import React from 'react'
//import axios from 'axios'

function Global() {

    return(
        <div>
            OOF
        </div>
    )

}

export default Global;